// OneTrust code for Analytics cookies is "C0002"
export const areAnalyticsCookiesEnabled = () => {
  if (
    typeof window !== 'undefined' &&
    window.OptanonActiveGroups &&
    window.OptanonActiveGroups.includes('C0002')
  ) {
    return true;
  }

  return false;
};
