import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Row, Col } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { submitFormUrl, reCaptchaKey } from '../../constants/config';
import { ContactBayerContainer } from './contact-form.css';
import { IoIosArrowForward, IoIosClose } from 'react-icons/io';
import { trackAnalytics } from '../../helpers/trackAnalytics';
import { MasterDataContext } from '../../store/masterDataContext';
import { areAnalyticsCookiesEnabled } from '../../helpers/areAnalyticsCookiesEnabled';

const ContactBayerForm = ({ data, isModal, itemId, title, hideModal }) => {
  const { masterData } = useContext(MasterDataContext);
  const [responseOk, setResponse] = useState(false);
  const [errorsList, setErrorsList] = useState({});

  useEffect(() => {
    makeResizeableTextarea('textarea');
    console.log(isModal, itemId, title, hideModal);
    trackAnalytics('ContactUsShow', {
      title: isModal ? 'Ask a Question' : 'Contact Us',
      TrialId: itemId ? itemId : '',
      TrialTitle: title ? title : '',
    });
  }, []);

  async function formSubmit(values, actions) {
    if (values.country.toLocaleLowerCase() === 'select country') {
      values.country = '';
    }
    let jsonObject = {
      TrialId: itemId,
      TrialTitle: title,
      From: values.firstName + ' ' + values.lastName,
      Email: values.email,
      Phone: values.phone,
      Country: values.country,
      Message: values.message,
      IsDefault: isModal ? false : true,
      LocationName: '',
      LocationAddress: '',
      gRecaptcha: await excuteRecaptcha(),
    };

    trackAnalytics(
      'ContactUsSubmit',
      //Destructuring the jsonObject to select just the needed fields
      (({
        TrialId,
        TrialTitle,
        Country,
        Message,
        LocationName,
        LocationAddress,
      }) => ({
        TrialId,
        TrialTitle,
        Country,
        Message,
        LocationName,
        LocationAddress,
      }))(jsonObject)
    );

    if (
      areAnalyticsCookiesEnabled() &&
      typeof window !== 'undefined' &&
      window.ga
    ) {
      try {
        window.ga.getAll().forEach(_ga => {
          _ga.send(
            'event',
            'Mails',
            'Mails',
            isModal ? 'Ask a Question' : 'Contact Us'
          );
        });
      } catch (ev) {
        console.log(ev);
      }
    }

    await fetch(submitFormUrl(), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonObject),
    })
      .then(response => {
        if (response.status === 204) {
          return {
            status: 'options',
          };
        }
      })
      .then(() => {
        setResponse(true);
        actions.resetForm();
        actions.setSubmitting(false);
      });
  }

  async function excuteRecaptcha() {
    return new Promise(resolve => {
      window.grecaptcha.execute(reCaptchaKey()).then(res => {
        resolve(res);
      });
    });
  }

  const makeResizeableTextarea = area => {
    let textarea = document.getElementById(area);
    let grippie = document.getElementById('grippie');
    const minimum_size = 84;
    let original_height = 0;
    let original_mouse_y = 0;
    grippie.addEventListener('mousedown', function(e) {
      e.preventDefault();
      original_height = parseFloat(
        getComputedStyle(textarea, null)
          .getPropertyValue('height')
          .replace('px', '')
      );
      original_mouse_y = e.pageY;
      textarea.style.opacity = 0.25;
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    });
    function resize(e) {
      const height = original_height + (e.pageY - original_mouse_y);
      if (height > minimum_size) {
        textarea.style.height = height + 'px';
      }
    }
    function stopResize() {
      textarea.style.opacity = 1;
      window.removeEventListener('mousemove', resize);
    }
  };

  const setErrors = errors => {
    setErrorsList(errors);
    if (isModal) {
      Object.keys(errors).length > 0 && window !== 'undefined'
        ? window.alert(errors[Object.keys(errors)[0]])
        : null;
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

  return (
    <ContactBayerContainer>
      {isModal ? <IoIosClose onClick={hideModal} /> : null}
      {responseOk && !isModal ? (
        <div className="msg-sended">
          <p className={'thank-you-msg'}>{data.contactBayerEnJson.thankYou}</p>
        </div>
      ) : null}
      {!isModal &&
      (errorsList.firstName || errorsList.lastName || errorsList.email) ? (
        <div className="error-list">
          <ul>
            {errorsList.firstName ? <li>{errorsList.firstName}</li> : null}
            {errorsList.lastName ? <li>{errorsList.lastName}</li> : null}
            {errorsList.email ? <li>{errorsList.email}</li> : null}
          </ul>
        </div>
      ) : null}
      <div className={isModal ? null : 'container-border'}>
        <h5>
          {isModal
            ? responseOk
              ? data.contactBayerEnJson.thankYou
              : data.contactBayerEnJson.modalTitle
            : data.contactBayerEnJson.contactTitle}
        </h5>
        {isModal ? (
          <p className={!responseOk ? 'study-title' : 'received-req'}>
            {!responseOk
              ? `${data.contactBayerEnJson.subtitle} - ${itemId} + ${title}`
              : data.contactBayerEnJson.receivedReq}
          </p>
        ) : null}
        <p className="subtitle" hidden={isModal && responseOk}>
          {data.contactBayerEnJson.mandatory}
        </p>
        <Formik
          enableReinitialize={false}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            country: '',
            message: '',
          }}
          validationSchema={
            isModal
              ? Yup.object().shape({
                  firstName: Yup.string().required(
                    data.contactBayerEnJson.errors.firstName
                  ),
                  lastName: Yup.string().required(
                    data.contactBayerEnJson.errors.lastName
                  ),
                  email: Yup.string()
                    .required(data.contactBayerEnJson.errors.email)
                    .email(data.contactBayerEnJson.errors.emailValid),
                  country: Yup.string().required(
                    data.contactBayerEnJson.errors.country
                  ),
                })
              : Yup.object().shape({
                  firstName: Yup.string().required(
                    data.contactBayerEnJson.errors.firstName
                  ),
                  lastName: Yup.string().required(
                    data.contactBayerEnJson.errors.lastName
                  ),
                  email: Yup.string()
                    .email(data.contactBayerEnJson.errors.email)
                    .required(data.contactBayerEnJson.errors.emailValid),
                })
          }
          onSubmit={formSubmit}
          render={({ isSubmitting, errors, validateForm }) => (
            <Form
              className={isModal ? 'modal-form-style' : ''}
              hidden={isModal && responseOk}
            >
              <Row>
                <Col md={6} xs={12}>
                  <h3>
                    {`${data.contactBayerEnJson.personalDataInputs[0].inputLabel} *`}
                  </h3>
                  <Field
                    className={!isModal && errors.firstName ? 'required' : ''}
                    type="text"
                    name="firstName"
                    maxLength="128"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <h3>
                    {`${data.contactBayerEnJson.personalDataInputs[1].inputLabel} *`}
                  </h3>
                  <Field
                    className={!isModal && errors.lastName ? 'required' : ''}
                    type="text"
                    name="lastName"
                    maxLength="128"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <h3>
                    {`${data.contactBayerEnJson.personalDataInputs[2].inputLabel} *`}
                  </h3>
                  <Field
                    className={!isModal && errors.email ? 'required' : ''}
                    type="text"
                    name="email"
                    maxLength="128"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <h3>
                    {data.contactBayerEnJson.personalDataInputs[3].inputLabel}
                  </h3>
                  <Field type="text" name="phone" maxLength="100" />
                </Col>
                <Col md={6} xs={12}>
                  <h3>
                    {`${
                      data.contactBayerEnJson.personalDataInputs[4].inputLabel
                    }${isModal ? ' *' : ''}`}
                  </h3>
                  <Field component="select" name="country">
                    <option>
                      {
                        data.contactBayerEnJson.personalDataInputs[4]
                          .inputPlaceholder
                      }
                    </option>
                    {masterData &&
                      Array.isArray(masterData.COUNTRY) &&
                      masterData.COUNTRY.sort(
                        (a, b) => a.SortOrder - b.SortOrder
                      ).map(country => (
                        <option
                          key={country.InternalValue}
                          value={country.InternalValue}
                        >
                          {country.DisplayValue}
                        </option>
                      ))}
                  </Field>
                </Col>
                <Col md={6} xs={12}>
                  <p>{data.contactBayerEnJson.note}</p>
                </Col>
                <Col xs={12} className="textarea-col">
                  <h3>{data.contactBayerEnJson.msgInputLabel}</h3>
                  <Field
                    id="textarea"
                    component="textarea"
                    name="message"
                    maxLength="5000"
                    cols="60"
                    rows="3"
                  />
                  <div id="grippie" />
                </Col>
              </Row>
              <Row className={'section-footer'}>
                {isModal ? (
                  <Col lg={9} xs={12}>
                    <p className={'privacy-statement'}>
                      {data.contactBayerEnJson.terms} &nbsp;
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={data.contactBayerEnJson.termsLink}
                      >
                        {data.contactBayerEnJson.termsLinkText}
                      </a>
                    </p>
                  </Col>
                ) : null}
                <Col xs={12}>
                  <div
                    className="recaptcha-terms"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contactBayerEnJson.recaptchaTerms
                          .childMarkdownRemark.html,
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <button
                    className={'submit-btn'}
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      setResponse(false);
                      validateForm().then(error => {
                        setErrors(error);
                      });
                    }}
                  >
                    {data.contactBayerEnJson.submitBtn}
                    <span>
                      <IoIosArrowForward />
                    </span>
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    </ContactBayerContainer>
  );
};

ContactBayerForm.propTypes = {
  data: PropTypes.object,
  isModal: PropTypes.bool,
  title: PropTypes.string,
  itemId: PropTypes.string,
  headerTitle: PropTypes.string,
  staticTitle: PropTypes.string,
  hideModal: PropTypes.any,
};

const ContactBayerQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        contactBayerEnJson {
          contactTitle
          modalTitle
          subtitle
          mandatory
          personalDataInputs {
            inputLabel
            inputName
            inputPlaceholder
          }
          note
          msgInputLabel
          msgInputName
          terms
          termsLink
          termsLinkText
          submitBtn
          thankYouMsg
          thankYou
          receivedReq
          recaptchaTerms {
            childMarkdownRemark {
              html
            }
          }
          errors {
            firstName
            lastName
            email
            emailValid
            country
          }
        }
      }
    `}
    render={data => <ContactBayerForm data={data} {...props} />}
  />
);

export default ContactBayerQuery;
