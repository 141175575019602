import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import ContactForm from 'components/form/contact-form';
import { Fonts, Container, ContactPage } from '../global.css';

class ContactBayer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { data } = this.props;
    return (
      <Layout meta={data.contactBayerEnJson.seo}>
        <Fonts>
          <ContactPage>
            <Container>
              <ContactForm />
            </Container>
          </ContactPage>
        </Fonts>
      </Layout>
    );
  }
}

ContactBayer.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactBayer;

export const query = graphql`
  query ContactBayerQuery {
    contactBayerEnJson {
      seo {
        pageTitle
        pageDescription
      }
    }
  }
`;
