import styled from 'styled-components';

export const ContactBayerContainer = styled.div`
  position: relative;
  .container-border {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  form {
    width: 100%;
    padding: 0 16px 16px 16px;
    p {
      margin: 10px 0;
      text-align: left;
      padding: 0;
    }
    h3 {
      width: 100%;
      font-size: 18px;
      font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica,
        sans-serif;
      line-height: 24px;
      font-weight: bold;
      margin: 0;
      color: #10384f;
    }
    input {
      width: 100%;
      margin-top: 8px;
      margin-bottom: 16px;
      border-width: 1px;
      border-color: #10384f;
      color: #10384f;
      font-style: italic;
      font-size: 16px;
      line-height: 1.5;
      padding: 6px 14px;
    }
    select {
      height: 48px;
      width: 100%;
      margin-top: 8px;
      margin-bottom: 16px;
      border-width: 1px;
      border-color: #10384f;
      color: #10384f;
      font-style: italic;
      font-size: 16px;
      line-height: 1.5;
      padding: 5px 14px;
      background: transparent;
      option {
        font-size: 16px;
      }
      ::-ms-expand {
        -webkit-appearance: none;
        background-color: transparent;
        border: 0;
      }
    }
    .note {
      padding: 0;
      margin: 10px 0;
    }
    .resizeable-textarea {
      position: relative;
    }
    textarea {
      width: 100%;
      margin-top: 8px;
      border-width: 1px;
      border-color: #10384f;
      color: #10384f;
      font-style: italic;
      font-size: 16px;
      line-height: 1.5;
      padding: 5px 14px;
    }
    .required {
      border-width: 2px;
    }
    .textarea-col {
      margin-bottom: 24px;
    }
    input,
    select,
    textarea {
      :focus {
        background-color: #fff;
        outline: 0;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
      }
    }
    #grippie {
      background: #eee url(/images/grippie.png) no-repeat center 2px;
      border: 1px solid #ddd;
      border-top-width: 0;
      cursor: s-resize;
      height: 9px;
      position: relative;
      top: -3px;
    }
  }
  .recaptcha-terms {
    p {
      margin: 0;
      font-size: 16px;
      a {
        color: #00617f;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
  .submit-btn {
    border-radius: 0;
    background-color: #00617f;
    border: solid 1px #00617f;
    color: #ffffff;
    font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
      sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    height: 48px;
    padding-right: 72px;
    position: relative;
    padding-left: 15px;
    font-weight: bold;
    font-style: italic;
    float: right;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 16px;
    margin-top: 24px;
    span {
      width: 46px;
      height: 46px;
      background-color: #10384f;
      color: #ffffff;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 26px;
      padding: 8px;
      svg {
        vertical-align: unset;
        position: relative;
      }
      ::before {
        width: 0;
        height: 0;
        border-bottom: 46px solid #10384f;
        border-left: 14px solid transparent;
        content: '';
        position: absolute;
        left: -14px;
        top: 0;
      }
    }
    :focus {
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    }
    :hover {
      background-color: #0069d9;
      border-color: #0062cc;
    }
  }
  @media screen and (max-width: 991px) {
    .recaptcha-terms {
      p {
        font-size: 14px;
      }
    }
  }
`;
